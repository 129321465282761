<template>
	<b-modal
		id="modal-document-for-send-message"
		v-model="isOpen"
		:title="FormMSG(295, 'Refuse this document')"
		header-class="header-class-modal-doc-package"
		body-class="modal-layout"
		size="md"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:hide-footer="$screen.width > 576"
		:ok-title="FormMSG(136, 'Refuse')"
		:cancel-title="FormMSG(35, 'Cancel')"
		ok-variant="danger"
		cancel-variant="outline-primary"
		:cancel-disabled="isLoading"
		:ok-disabled="cantSendMessage || isLoading"
		@ok="handleSubmit"
		@hidden="emitEventClose"
	>
		<b-row>
			<b-col>
				<div class="form">
					<b-form-textarea
						id="textarea"
						v-model="messageToSend"
						:placeholder="this.FormMSG(24, 'Enter message here...')"
						no-resize
						rows="3"
						max-rows="6"
						required
						:state="messageStateError.global"
						@input="handleInputMessage"
					/>
					<div v-if="messageStateError.byLength" class="invalid-feedback">
						{{ FormMSG(25, 'Message length must be greater than 5 characters') }}
					</div>
					<div v-if="messageStateError.required" class="invalid-feedback">
						{{ FormMSG(26, 'Message required') }}
					</div>
				</div>
			</b-col>
		</b-row>

		<b-row v-if="$screen.width > 576" class="mt-20">
			<b-col cols="4" offset="4">
				<b-button variant="outline-primary" block :disabled="isLoading" @click="emitEventClose">
					{{ FormMSG(35, 'Cancel') }}
				</b-button>
			</b-col>
			<b-col cols="4">
				<b-button variant="danger" block :disabled="cantSendMessage || isLoading" @click="handleSubmit">
					{{ FormMSG(136, 'Refuse') }}
				</b-button>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { refuseDocument, addCommentForRefusedDocument } from '@/modules/onboarding/cruds/onboarding.crud';
import { getDocument } from '@/modules/document-package/cruds/document.crud';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';

export default {
	name: 'DialogForRefuseDocument',

	mixins: [languageMessages, globalMixin],

	props: {
		open: { type: Boolean, default: false },
		documentSelected: { type: Object, required: true }
	},

	data() {
		return {
			messageToSend: '',
			messageStateError: {
				global: null,
				byLength: null,
				required: null
			},
			cantSendMessage: true,
			isLoading: false
		};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		async handleSubmit() {
			if (_.isNil(this.messageStateError.global)) {
				this.isLoading = true;

				const document = await getDocument(this.documentSelected.docId);

				this.messageToSend += ` (${this.FormMSG(256, 'Refused Step ') + this.documentSelected.currentStep})`;

				await addCommentForRefusedDocument(document.xid, this.messageToSend, 0, this.documentSelected.documentDeliveryId);

				await refuseDocument(this.documentSelected.documentDeliveryId);

				this.$emit('dialog-for-refuse-document:success');
				this.emitEventClose();

				this.createToastForMobile(this.FormMSG(52, 'Success'), this.FormMSG(53, 'Message sended successfully'), '', 'success');

				this.isLoading = false;
			}
		},
		handleInputMessage(payload) {
			this.manageMessageStateError(payload);
		},
		manageMessageStateError(value) {
			if (value === '') {
				this.messageStateError.global = false;
				this.messageStateError.byLength = null;
				this.messageStateError.required = true;
				this.cantSendMessage = true;
			} else if (value.length <= 5) {
				this.messageStateError.global = false;
				this.messageStateError.byLength = true;
				this.messageStateError.required = null;
				this.cantSendMessage = true;
			} else {
				this.messageStateError.global = null;
				this.messageStateError.byLength = null;
				this.messageStateError.required = null;
				this.cantSendMessage = false;
			}
		},
		emitEventClose() {
			this.resetData();
			this.$emit('dialog-for-refuse-document:close');
		},
		resetData() {
			this.messageToSend = '';
			this.messageStateError = {
				global: null,
				byLength: null,
				required: null
			};
			this.cantSendMessage = true;
			this.isLoading = false;
		}
	}
};
</script>
