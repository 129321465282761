import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const getFollowUpList = async () => {
	try {
		const QUERY_GET_FOLLOW_UP_LIST = gql`
			query {
				GetOnBoardingFollowUpList {
					id
					createdAt
					updatedAt
					userId
					docId
					name
					firstName
					accountingCode
					department
					departmentName
					function
					functionName
					contractStartDate
					contractEndDate
					documentDeliveryId
					currentStep
					documentName
					documentStatus
					senderStatus
					documentDeliveryContractId
					toSign
					needValidation
					docValidationLevel
				}
			}
		`;

		const {
			data: { GetOnBoardingFollowUpList }
		} = await apollo.query({
			query: QUERY_GET_FOLLOW_UP_LIST,
			fetchPolicy: 'no-cache'
		});

		return GetOnBoardingFollowUpList;
	} catch (error) {
		console.log(error);
	}
};

export const sendReminder = async (docId, message) => {
	try {
		const MUTATION_SEND_REMINDER = gql`
			mutation ($docId: ID!, $message: String!) {
				SendReminder(DocId: $docId, Message: $message)
			}
		`;

		const {
			data: { SendReminder }
		} = await apollo.mutate({
			mutation: MUTATION_SEND_REMINDER,
			variables: {
				docId: parseInt(docId, 10),
				message
			}
		});

		return SendReminder;
	} catch (error) {
		console.log(error);
	}
};

export const getDocumentToValidate = async () => {
	try {
		const QUERY_GET_DOCUMENT_TO_VALIDATE = gql`
			query {
				GetDocumentToValidate {
					id
					createdAt
					updatedAt
					userId
					docId
					name
					firstName
					accountingCode
					department
					contractId
					departmentName
					function
					functionName
					contractStartDate
					contractEndDate
					documentDeliveryId
					currentStep
					documentName
					documentStatus
					senderStatus
					documentDeliveryContractId
					toSign
					needValidation
					docValidationLevel
				}
			}
		`;

		const {
			data: { GetDocumentToValidate }
		} = await apollo.query({
			query: QUERY_GET_DOCUMENT_TO_VALIDATE,
			fetchPolicy: 'no-cache'
		});

		return GetDocumentToValidate;
	} catch (error) {
		console.log(error);
	}
};

export const validateDocument = async (docDeliId) => {
	try {
		const MUTATION_VALIDATE_DOCUMENT = gql`
			mutation ($docDeliId: ID!) {
				ValidateDocument(DocDeliId: $docDeliId)
			}
		`;

		const {
			data: { ValidateDocument }
		} = await apollo.mutate({
			mutation: MUTATION_VALIDATE_DOCUMENT,
			variables: {
				docDeliId: parseInt(docDeliId, 10)
			}
		});

		return ValidateDocument;
	} catch (error) {
		console.log(error);
	}
};

export const refuseDocument = async (docDeliId) => {
	try {
		const MUTATION_REFUSE_DOCUMENT = gql`
			mutation ($docDeliId: ID!) {
				RefuseDocument(DocDeliId: $docDeliId)
			}
		`;

		const {
			data: { RefuseDocument }
		} = await apollo.mutate({
			mutation: MUTATION_REFUSE_DOCUMENT,
			variables: {
				docDeliId: parseInt(docDeliId, 10)
			}
		});

		return RefuseDocument;
	} catch (error) {
		console.log(error);
	}
};

export const addCommentForRefusedDocument = async (xid, message, doNotLike = 0, docDeliId = 0) => {
	try {
		const MUTATION_ADD_ITEM_COMMENT = gql`
			mutation ($xid: String!, $message: String, $doNotLike: Int, $docDeliId: ID) {
				AddItemComment(Xid: $xid, Message: $message, DoNotLike: $doNotLike, DocDeliId: $docDeliId) {
					id
					name
					firstName
					insertedOn
					picture
					imageChildId
					message
					doLike
					doNotLike
					xPos
					yPos
					pinInfoCode
				}
			}
		`;

		const {
			data: { AddItemComment }
		} = await apollo.mutate({
			mutation: MUTATION_ADD_ITEM_COMMENT,
			variables: {
				xid,
				message,
				doNotLike,
				docDeliId: parseInt(docDeliId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return AddItemComment;
	} catch (e) {
		console.log(e);
	}
};
