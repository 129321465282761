var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-document-for-send-message",
        title: _vm.FormMSG(295, "Refuse this document"),
        "header-class": "header-class-modal-doc-package",
        "body-class": "modal-layout",
        size: "md",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-footer": _vm.$screen.width > 576,
        "ok-title": _vm.FormMSG(136, "Refuse"),
        "cancel-title": _vm.FormMSG(35, "Cancel"),
        "ok-variant": "danger",
        "cancel-variant": "outline-primary",
        "cancel-disabled": _vm.isLoading,
        "ok-disabled": _vm.cantSendMessage || _vm.isLoading,
      },
      on: { ok: _vm.handleSubmit, hidden: _vm.emitEventClose },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c("b-form-textarea", {
                  attrs: {
                    id: "textarea",
                    placeholder: this.FormMSG(24, "Enter message here..."),
                    "no-resize": "",
                    rows: "3",
                    "max-rows": "6",
                    required: "",
                    state: _vm.messageStateError.global,
                  },
                  on: { input: _vm.handleInputMessage },
                  model: {
                    value: _vm.messageToSend,
                    callback: function ($$v) {
                      _vm.messageToSend = $$v
                    },
                    expression: "messageToSend",
                  },
                }),
                _vm.messageStateError.byLength
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              25,
                              "Message length must be greater than 5 characters"
                            )
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm.messageStateError.required
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(26, "Message required")) +
                          "\n\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.$screen.width > 576
        ? _c(
            "b-row",
            { staticClass: "mt-20" },
            [
              _c(
                "b-col",
                { attrs: { cols: "4", offset: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-primary",
                        block: "",
                        disabled: _vm.isLoading,
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(35, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "danger",
                        block: "",
                        disabled: _vm.cantSendMessage || _vm.isLoading,
                      },
                      on: { click: _vm.handleSubmit },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(136, "Refuse")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }